<template>
  <div class="apply-page">
    
    <topbar2></topbar2>
  <div class="body-bg">
<div class="center-body">
<div class="settop">
            <span class="first"><img src="@/assets/finish.png"></span>
            <p class="first_text">填写供应商加盟资料</p>
            <p class="complateline"></p>
            <span class="first">2</span>
            <p class="first_text">选择供应商加盟类目</p>
            <p class="preline"></p>
            <span class="second">3</span>
            <p class="second_text">完成</p>
        </div>
        <div class="choose_class">
            <p>请选择加盟类目</p>
           <RadioGroup v-model="goodsclass" vertical @on-change="xuanlei()">
        <Radio label="1">
           
            <span>话费充值（移动、联通、电信）</span>
        </Radio>
        <Radio label="2">
          
            <span>会员/音乐充值（优酷、爱奇艺、腾讯、芒果TV、QQ音乐、酷狗音乐等）</span>
        </Radio>
        <Radio label="3">
         
            <span>游戏点卡充值（游戏皮肤、点卡等）</span>
        </Radio>
        <Radio label="4">
            
            <span>QQ点卡充值</span>
        </Radio>
        </RadioGroup>
        <div class="submit" v-if="show">
          <span  type="danger" size="large" class="nextbtn" @click="step">下一步</span>
        </div>
        <div class="submit" v-else>
          <span  type="danger" size="large" class="btn">下一步</span>
        </div>
        </div>
</div>
  </div>
<!-- 导航栏 -->
</div>
</template>

<script>
import Topbar2 from '@/components/topbar2'
export default {
  name: "apply-class",
  // inject:['app'],
  components: { 
  Topbar2
  },
  data() {
    return {
      user: "",
      pwd: "",
      province:"",
      city:"",
      addre:"",
      user_type:"1",
      goodsclass:"",
      show:false,
    };
  },
  mounted() {
        this.$parent.$parent.$parent.sskj = false;
  },
  methods: {
    xuanlei(){
        this.show=true;
    },
    step(){
      this.$router.push('./apply_complated')
    }
  }
};
</script>

<style lang="less" scoped>
.apply-page {
  position: relative;
  padding: 0px;
  margin: 0;
  width: 100%;
  height: 100%;
  //导航栏
.body-bg{width:100%;
height:760px;
position: absolute;
top: 80px;
padding-top: 20px;
background:rgba(242,243,250,1);}
.center-body{width:1300px;
height:90%;
background:rgba(255,255,255,1);
border:1px solid rgba(222,222,222,1);
border-radius:8px;
margin: 0 auto;
box-sizing: border-box;
overflow: hidden;
position: relative;
.settop{height: 40px;width: 1150px;position: absolute;top: 100px;left: 160px;right: 160px;
  >span{display: inline-block;}
  >p{display: inline-block;}
  .first{
      width: 36px;
      height: 36px;
      text-align: center;
      background:rgba(255,255,255,1);
    border:2px solid rgba(222,222,222,1);
    border-radius:50%;
    font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(61,49,226,1);
line-height:32px;margin-left: 18px;}
.first_text{
height:20px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(55,67,214,1);
line-height:26px;
margin: 0;
margin-left: 18px;
}
.second{width:36px;
height:36px;
text-align: center;
background:rgba(204,204,204,1);
border-radius:50%;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(255,255,255,1);
line-height:36px;
margin-left: 18px;}
.second_text{
height:20px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(51,51,51,1);
line-height:26px;
margin: 0;
margin-left: 18px;
}
.preline{width:200px;
height:4px;
background:rgba(204,204,204,1);
border-radius:1px;
margin-left: 18px;
margin-bottom: 5px;}
  }
  .complateline{background:  #3D31E2;
  width:200px;
height:4px;
border-radius:1px;
margin-left: 18px;
margin-bottom: 5px;}
  .choose_class{width: 1000px;margin: 235px auto;
  >p{width:149px;
height:21px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(51,51,51,1);
line-height:26px;
display: inline-block;
margin:0 0 40px 270px;}
  .ivu-radio-group{margin: 0 300px;}
  }
    .submit {
      height: 60px;
      display: inline-block;
      margin: 50px 300px;
      cursor: pointer;
    }
    .submit .nextbtn {
      width: 360px;
      height: 60px;
      background: rgba(55, 67, 214, 1);
      border-radius: 30px;
      display: block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;
    }
    .btn{width: 360px;
      height: 60px;
      background:rgba(153,153,153,1);
      border-radius: 30px;
      display: block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;}
}
}


</style>