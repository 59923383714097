<template>
    <applyclass />
</template>

<script>
// @ is an alias to /src
import applyclass from "@/components/apply_class";
export default {
  name: "a_class",
  components: {
    applyclass,
  }
};
</script>